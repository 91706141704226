<template>
  <component :is="layout" />
</template>
<script>
import EmptyLayout from '@/Layouts/EmptyLayout.vue';
import LandingLayout from '@/Layouts/LandingLayout.vue';
import LandingLayout1 from '@/Layouts/LandingLayout1.vue';
import LandingLayout2 from '@/Layouts/LandingLayout2.vue';
import ApplicantLayout from '@/Layouts/ApplicantLayout.vue';
import Track2ApplicantLayout from '@/Layouts/Track2ApplicantLayout.vue';
import Track2ApplicantEmptyLayout from '@/Layouts/Track2ApplicantEmptyLayout.vue';
import ApplicantEmptyLayout from '@/Layouts/ApplicantEmptyLayout.vue';
import MentorEmptyLayout from '@/Layouts/MentorEmptyLayout.vue';
import MentorLayout from '@/Layouts/MentorLayout.vue';
import JudgeEmptyLayout from '@/Layouts/JudgeEmptyLayout.vue';
import JudgeLayout from '@/Layouts/JudgeLayout.vue';
import RegLayout from '@/Layouts/RegLayout.vue';

export default {
  name: "App",
  data() {
    return {
      layout: null,
    };
  },
  components: {
    EmptyLayout,
    LandingLayout,ApplicantLayout,Track2ApplicantLayout,Track2ApplicantEmptyLayout,ApplicantEmptyLayout,RegLayout,MentorEmptyLayout,MentorLayout,JudgeLayout,JudgeEmptyLayout,LandingLayout1,LandingLayout2
  },
  watch: {
    $route(to) {
      if (to.meta.layout !== undefined) {
        this.layout = to.meta.layout
      } else {
        this.layout = "EmptyLayout"
      }
    },
  },
};
</script>
