<template class="">
  <div class="bgNavy">
    <div class="bgw pf ov ba fs0 z100">
      <div class="flexi m_ph10 pv5">
        <tbl_nm>
          <cl class="vm wa l">
            <img src="@/assets/imgs/logo_new.png" class="w100" alt="" style="max-width: 100px;">
          </cl>
          <cl class="colSep pv5"></cl>
          <cl class="vm wa l cap m_h">
            <tbl_nm>
              <cl class="vm wa c">
                <div class="g_nm ml10 fs16 cNavy pointer navBarItem vm ltr" @click="gotoinnerlink('about')">
                  <span>{{ $t('about_expro')}}</span>
                  <div class="lineNav"></div>
                </div>
              </cl>
              <cl class="vm wa c">
                <div class="g_nm ml10 fs16 cNavy pointer navBarItem vm" @click="gotoinnerlink('explore')">
                  <span>{{ $t("Kafa'athon intro") }}</span>
                  <div class="lineNav"></div>
                </div>
              </cl>
              <cl class="vm wa c">
                <div class="g_nm ml10 fs16 cNavy pointer navBarItem vm" @click="gotoinnerlink('faq')">
                  <span>{{ $t('faqs') }}</span>
                  <div class="lineNav"></div>
                </div>
              </cl>
              <cl class="vm wa c">
                <div class="g_nm ml10 fs16 cNavy pointer navBarItem vm" @click="gotoinnerlink('footer')">
                  <span>{{ $t('contact_us') }}</span>
                  <div class="lineNav"></div>
                </div>
              </cl>
            </tbl_nm>
          </cl>
          <cl class="vm nw m_r" style="width: 256px;">
            <router-link :to="{ name: 'track1' }" class="mr5 ml5 m_h"> <button class="vm navRegBtn cap">{{ $t('Track One') }}</button></router-link>
            <router-link :to="{ name: 'track2' }" class="mr5 ml5 m_h"> <button class="vm navRegBtn cap">{{ $t('Track Two') }}</button></router-link>
            <div class="vm g_nm mr10 m_mr0 ml10 m_ml10 pointer " @click="changeLang()"><span class="mdi mdi-earth fs30 cAqua vm mt2"></span></div>
            <div class="vm g_nm pointer ml10 h m_sib" @click="openMenu()"><span class="mdi mdi-menu fs30 cAqua vm mt2"></span></div>

          </cl>
        </tbl_nm>
        <div class="menuBar h m_sb pt30 z500">
          <div class="ov mt10 mr20 ra z200"><span class="mdi mdi-close cw fs30" @click="closeMenu()"></span></div>
              <div class="vm l ph30 fs16 cap cw pv10 ltr" style="border-bottom: 1px solid white;" @click="gotoinnerlink('about')">{{ $t('about_expro')}}</div>
              <div class="vm l ph30 fs16 cap cw pv10" style="border-bottom: 1px solid white;" @click="gotoinnerlink('explore')">{{ $t("Kafa'athon intro") }}</div>
              <div class="vm l ph30 fs16 cap cw pv10" style="border-bottom: 1px solid white;" @click="gotoinnerlink('faq')">{{ $t('faqs') }}</div>
              <div class="vm l ph30 fs16 cap cw pv10" style="border-bottom: 1px solid white;" @click="gotoinnerlink('footer')">{{ $t('contact_us') }}</div>
              <div class="vm c ph30 fs16 cap cw pv20"><router-link :to="{ name: 'track1' }" class=""><button class="vm navRegBtn cap">{{ $t('Track One') }}</button></router-link></div>
              <div class="vm c ph30 fs16 cap cw pv20"><router-link :to="{ name: 'track2' }" class=""><button class="vm navRegBtn cap">{{ $t('Track Two') }}</button></router-link></div>
        </div>
      </div>
    </div>
  </div>
  <router-view></router-view>
  <div class="fs0" style="background-color: #031E44;">
    <div class="bgw footerDiv pv10  c" id="footer">
      <div class="ph30 m_ph0">
        <div class="flexi l fs0">
          <tbl>
            <cl class="vm w20 l m_c" style="min-width: 100px; max-widdth: 100px;">
              <img src="@/assets/imgs/logo_new.png" alt="" class="w100 m_mt30 g_nm"  style="max-width: 100px;"/>
            </cl>
            <cl class="colSep pv10"></cl>

            <cl class="vm w60 c m_l">
              <div class="g_nm fs17 vm cNavy cap">{{ $t('contact_us') }}:</div>
              <div class="g_nm pl8 vm">
                <div class="round3x ph10 p2" style="background-color:rgb(236 236 236)">
                  <a :href="'mailto:'+configCms.email" target="_blank">
                    <div class=" round100 g_nm vm " style="padding:15px;"><span class="cc cNavy mdi mdi-email-outline fs20"></span></div>
                    <div class="g_nm ml10 fs16 cNavy vm">{{ configCms.email }}</div>
                  </a>
                </div>
              </div>
            </cl>
            <cl class="colSep pv10"></cl>
            <cl class="vm  w20 nw r m_r fs0 cNavy">
              <div class="g_nm ph5 vm"><a class="" v-if="configCms.snapchat" target="_blank" :href="configCms.snapchat"><img src="@/assets/imgs/snapchat.png" style="height:18px"/></a></div>
              <div class="g_nm ph5 vm"><a class="" v-if="configCms.youtube" target="_blank" :href="configCms.youtube"><img src="@/assets/imgs/youtube.png" style="height:18px"/></a></div>
                <div class="g_nm ph5 vm"><a class="" v-if="configCms.linkedin" target="_blank" :href="configCms.linkedin"><img src="@/assets/imgs/linkedin.png" style="height:18px"/></a></div>
                <div class="g_nm ph5 vm"><a class="" v-if="configCms.instagram" target="_blank" :href="configCms.instagram"><img src="@/assets/imgs/insta.png" style="height:18px"/></a></div>
                <div class="g_nm ph5 vm"><a class="" v-if="configCms.twitter" target="_blank" :href="configCms.twitter"><img src="@/assets/imgs/x.png" style="height:18px"/></a></div>
                <div class="g_nm ph5 vm"><a class="" v-if="configCms.facebook" target="_blank" :href="configCms.facebook"><img src="@/assets/imgs/face.png" style="height:22px"/></a></div>
            </cl>
          </tbl>
          <tbl class="pv15 h">
            <cl class="w25 vt">
              <a href="" class="fs13 cNavy">Privacy Policy</a>
            </cl>
            <cl class="colSep"></cl>
            <cl class="w25 vt">
              <a href="" class="fs13 cNavy">Terms &  Conditions</a>
            </cl>
            <cl class="colSep"></cl>
            <cl class="w25 vt"></cl>
            <cl class="colSep"></cl>
            <cl class="w25 vt">
              <a href="" class="fs13 cNavy">Copyright </a>
            </cl>
          </tbl>
        </div>
      </div>
      <hr class="mt10 bgNavy">
    </div>
  </div>
  <div v-if="$store.state.loader==1||$store.state.pageLoader==1" class="z1000 pf ov bgw60">
    <div class="cc">
      <img src="@/assets/imgs/loader.gif"  style="max-width: 250px; opacity: 0.7;"/>
    </div>
  </div>
</template>
<script>
  export default {
    name: "EmptyLayout",
    data(){
      return {
        configCms:{},
      }
    },
    created(){
      if(this.$cookies.isKey("_HackLang")){
        if(this.$cookies.get("_HackLang")=='en'){
          this.$i18n.locale='ar';
        }
        else{
          this.$i18n.locale='en';
        }
        this.changeLang()
      }
      this.$store.state.loader=1;
    Promise.all([
      this.axios.get(this.$store.state.BaseApiURL + "get-configurations", {}),
    ])
    .then(responses => {
      this.configCms=responses[0].data.data;
      this.$store.state.loader=0;
    })
    .catch(error => {
      this.$toast.open({ message: this.$t('failed_to_get_data'), type: 'error', duration:5000, position:'top-right',});
    });
      
    },
    methods:{
      changeLang(){
      if(this.$i18n.locale=="en"){
        $('html').attr('lang', 'ar');  
        this.$i18n.locale='ar';
        $('body').addClass('rtl');
        const expirationTime =  100 * 60 * 60;
        this.$cookies.set("_HackLang", 'ar', expirationTime);
      }
      else{
        $('html').attr('lang', 'en');  
        this.$i18n.locale='en';
        $('body').removeClass('rtl');
        const expirationTime =  100 * 60 * 60;
        this.$cookies.set("_HackLang", 'en', expirationTime);
      }
    },
    gotoinnerlink(section_id){
      var x = $("#" + section_id).offset().top - 100;
      $("html, body").animate({scrollTop: x,},500);
      this.closeMenu()
    },
    openMenu(){
      $('.menuBar').addClass('active')
    },
    closeMenu(){
      $('.menuBar').removeClass('active')
    }

  }
  }
</script>

