import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "./i18n";
import VueAxios from "vue-axios";
import axios from "axios";
import Select2 from "vue3-select2-component";
import VueToast from 'vue-toast-notification';
import VueCookies from "vue-cookies";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import vue3GoogleLogin from 'vue3-google-login';
import { useRecaptcha } from 'vue-recaptcha-v3';

import 'vue-toast-notification/dist/theme-sugar.css';
import "maz-ui/css/main.css";
import "./assets/css/bea.css";
import "./assets/css/mdi.css";
import "./assets/css/customStyle.css";

const app = createApp(App).use(store).use(router);
app.use(vue3GoogleLogin, {
  // clientId: '363169735438-rf02ja12esfntrsra22ju652upqe3bhr.apps.googleusercontent.com' //localhost
  clientId: '918883803760-ltl7u58sb2ctrhoag9jco1csp62k06s8.apps.googleusercontent.com' //online
})

app.use(useRecaptcha, {siteKey:'6Lfm8mEqAAAAAERDRRGW9gXj9eHZ0iRxGKRpb4C3'});
app.use(i18n);
app.use(VueAxios, axios);
app.component("Select2", Select2);
app.use(VueToast);
app.use(VueCookies);
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log(app.$cookies.isKey("_HackAppU"))
      if(app.$cookies.isKey("_HackAppU")) {
        app.$cookies.remove("_HackAppAT")
        app.$cookies.remove("_HackAppU")
        router.push({ name: 'ApplicantLogin' }); 
      }
      else if(app.$cookies.isKey("_HackMenU")) {
        app.$cookies.remove("_HackMenAT")
        app.$cookies.remove("_HackMenU")
        router.push({ name: 'MentorLogin' }); 
      }
      else if(app.$cookies.isKey("_HackTrack2AppU")) {
        app.$cookies.remove("_HackTrack2AppAT")
        app.$cookies.remove("_HackTrack2AppU")
        router.push({ name: 'Track2ApplicantLogin' }); 
      }
      else if(app.$cookies.isKey("_HackJudU")) {
        app.$cookies.remove("_HackJudAT")
        app.$cookies.remove("_HackJudU")
      }
      else{
        router.push({ name: 'home' }); 
      }
    }
    return Promise.reject(error);
  }
);


app.use(VueSweetalert2);
app.mount("#app");
