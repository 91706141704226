<template>
  <router-view></router-view>
  <div v-if="$store.state.loader==1" class="z1000 pf ov bgw60">
    <div class="cc">
      <img src="@/assets/imgs/loader.gif"  style="max-width: 250px; opacity: 0.7;"/>
    </div>
  </div>
</template> 

<script>
export default {
  name: 'ApplicantEmptyLayout',
  created(){
    if(this.$cookies.isKey("_HackLang")){
      if(this.$cookies.get("_HackLang")=='en'){
        this.$i18n.locale='ar';
      }
      else{
        this.$i18n.locale='en';
      }
      this.changeLang()
    }
    
  },
  
  methods: {
    logoutApplicant(){
      this.$cookies.remove("_HackAppAT")
      this.$cookies.remove("_HackAppU")
      this.$router.push({ name: 'ApplicantLogin'});  
    },
    changeLang(){
      if(this.$i18n.locale=="en"){
        $('html').attr('lang', 'ar');  
        this.$i18n.locale='ar';
        $('body').addClass('rtl');
        const expirationTime =  100 * 60 * 60;
        this.$cookies.set("_HackLang", 'ar', expirationTime);
      }
      else{
        $('html').attr('lang', 'en');  
        this.$i18n.locale='en';
        $('body').removeClass('rtl');
        const expirationTime =  100 * 60 * 60;
        this.$cookies.set("_HackLang", 'en', expirationTime);
      }
    },
  }
}
</script>
