import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import( '../views/HomeView.vue'),
    meta: {
      layout: "LandingLayout",
    },
  },
  {
    path: '/track1',
    name: 'track1',
    component: () => import( '../views/TrackOneView.vue'),
    meta: {
      layout: "LandingLayout1",
    },
  },
  {
    path: '/track2',
    name: 'track2',
    component: () => import( '../views/TrackTwoView.vue'),
    meta: {
      layout: "LandingLayout2",
    },
  },
  // {
  //   path: '/registration',
  //   // component: Parent,
  //   children: [
  //     {
  //       path: 'step0',
  //       name:'ApplicantStep0',
  //       component: () => import( '../views/Applicant/Registration/Nafath.vue'),
  //     },
  //     {
  //       path: 'step1',
  //       name:'ApplicantStep1',
  //       meta:{
  //         layout: "RegLayout"
  //       },
  //       component: () => import( '../views/Applicant/Registration/Step1.vue'),
  //     },
  //     {
  //       path: 'step2',
  //       name:'ApplicantStep2',
  //       meta:{
  //         layout: "RegLayout"
  //       },
  //       component: () => import( '../views/Applicant/Registration/Step2.vue'),
  //     },
  //     {
  //       path: 'step3',
  //       name:'ApplicantStep3',
  //       meta:{
  //         layout: "RegLayout"
  //       },
  //       component: () => import( '../views/Applicant/Registration/Step3.vue'),
  //     },
  //     {
  //       path: 'thankyou',
  //       name: 'ApplicantThankyou',
  //       component: () => import( '../views/Applicant/Registration/ThankYou.vue'),
  //     },
     
  //   ]
  // },
  {
    path: '/applicant',
    children: [
      {
        path:"create-password",
        name:"ApplicantCreatePassword",
        meta: {
          layout: "ApplicantEmptyLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/ForgetPassword.vue'),
      },
      {
        path:"login",
        name:"ApplicantLogin",
        meta: {
          layout: "ApplicantEmptyLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/Login.vue'),
      },
      {
        path:"team-invitation",
        name:"TeamInvitation",
        meta: {
          layout: "ApplicantEmptyLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/TeamInvitation.vue'),
      },
      
      {
        path:"forgot-password",
        name:"ApplicantForgetPassword",
        meta: {
          layout: "ApplicantEmptyLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/ForgetPassword.vue'),
      },
      
    ]
  },
  // {
  //   path: '/track2/registration',
  //   // component: Parent,
  //   children: [
  //     {
  //       path: 'step0',
  //       name:'Track2ApplicantStep0',
  //       component: () => import( '../views/Track2Applicant/Registration/Nafath.vue'),
  //     },
  //     {
  //       path: 'step1',
  //       name:'Track2ApplicantStep1',
  //       meta:{
  //         layout: "RegLayout"
  //       },
  //       component: () => import( '../views/Track2Applicant/Registration/Step1.vue'),
  //     },
  //     {
  //       path: 'step2',
  //       name:'Track2ApplicantStep2',
  //       meta:{
  //         layout: "RegLayout"
  //       },
  //       component: () => import( '../views/Track2Applicant/Registration/Step2.vue'),
  //     },
  //   ]
  // },
  {
    path: '/mentor',
    children: [
      {
        path:"reset-password",
        name:"MentorResetPassword",
        meta: {
          layout: "MentorEmptyLayout",
        },
        component: () => import( '../views/Mentor/Dashboard/ResetPassword.vue'),
      },
      {
        path:"create-password",
        name:"MentorCreatePassword",
        meta: {
          layout: "MentorEmptyLayout",
        },
        component: () => import( '../views/Mentor/Dashboard/ForgetPassword.vue'),
      },
      {
        path:"login",
        name:"MentorLogin",
        meta: {
          layout: "MentorEmptyLayout",
        },
        component: () => import( '../views/Mentor/Dashboard/Login.vue'),
      },
      {
        path:"forget-password",
        name:"MentorForgetPassword",
        meta: {
          layout: "MentorEmptyLayout",
        },
        component: () => import( '../views/Mentor/Dashboard/ForgetPassword.vue'),
      },
    ]
  },
  {
    path: '/mentor',
    meta:{
      requiredMentorAuth:true,
    },
    children: [
      {
        path:"dashboard",
        name:"MentorDashboard",
        meta: {
          layout: "MentorLayout",
        },
        component: () => import( '../views/Mentor/Dashboard/Dashboard.vue'),
      },
      {
        path:"profile",
        name:"MentorProfile",
        meta: {
          layout: "MentorLayout",
        },
        component: () => import( '../views/Mentor/Dashboard/Profile.vue'),
      },
    ]
  },
  {
    path: '/applicant',
    meta:{
      requiredApplicantAuth:true,
    },
    children: [
      {
        path:"create-or-join-team",
        name:"CreateOrJoinTeam",
        meta: {
          layout: "ApplicantEmptyLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/CreateOrJoinTeam.vue'),
      },
      {
        path:"create-team",
        name:"CreateTeam",
        meta: {
          layout: "ApplicantEmptyLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/CreateTeam.vue'),
      },
      {
        path:"join-team",
        name:"JoinTeam",
        meta: {
          layout: "ApplicantEmptyLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/JoinTeam.vue'),
      },
      {
        path:"accept-reject-invitation",
        name:"acceptOrRejectInvitation",
        meta: {
          layout: "ApplicantEmptyLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/acceptOrRejectInvitation.vue'),
      },
      {
        path:"mentor",
        name:"BookMentor",
        meta: {
          layout: "ApplicantLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/Mentor.vue'),
      },
      {
        path:"invite-member",
        name:"InviteMember",
        meta: {
          layout: "ApplicantLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/SendInvitation.vue'),
      },
      {
        path:"merge-team",
        name:"MergeTeam",
        meta: {
          layout: "ApplicantLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/MergeTeam.vue'),
      },
      {
        path:"feedback",
        name:"Feedback",
        meta: {
          layout: "ApplicantLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/Feedback.vue'),
      },
      {
        path:"members",
        name:"Members",
        meta: {
          layout: "ApplicantLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/Members.vue'),
      },
      {
        path:"profile",
        name:"Profile",
        meta: {
          layout: "ApplicantLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/Profile.vue'),
      },
      {
        path:"dashboard",
        name:"Dashboard",
        meta: {
          layout: "ApplicantLayout",
        },
        component: () => import( '../views/Applicant/Dashboard/Dashboard.vue'),
      },
    ]
  },
  {
    path: '/judge',
    children: [
      {
        path:"reset-password",
        name:"JudgeResetPassword",
        meta: {
          layout: "JudgeEmptyLayout",
        },
        component: () => import( '../views/Judge/ResetPassword.vue'),
      },
      {
        path:"create-password",
        name:"JudgeCreatePassword",
        meta: {
          layout: "JudgeEmptyLayout",
        },
        component: () => import( '../views/Judge/ForgetPassword.vue'),
      },
      {
        path:"login",
        name:"JudgeLogin",
        meta: {
          layout: "JudgeEmptyLayout",
        },
        component: () => import( '../views/Judge/Login.vue'),
      },
      {
        path:"forget-password",
        name:"JudgeForgetPassword",
        meta: {
          layout: "JudgeEmptyLayout",
        },
        component: () => import( '../views/Judge/ForgetPassword.vue'),
      },
    ]
  },
  {
    path: '/judge',
    meta:{
      requiredJudgeAuth:true,
    },
    children: [
      {
        path:"dashboard",
        name:"JudgeDashboard",
        meta: {
          layout: "JudgeLayout",
        },
        component: () => import( '../views/Judge/Dashboard.vue'),
      },
      {
        path:"team",
        name:"JudgeTeam",
        meta: {
          layout: "JudgeLayout",
        },
        component: () => import( '../views/Judge/JudgeTeam.vue'),
      },
    ]
  },
  {
    path: '/track2/applicant',
    children: [
      {
        path:"create-password",
        name:"Track2ApplicantCreatePassword",
        meta: {
          layout: "Track2ApplicantEmptyLayout",
        },
        component: () => import( '../views/Track2Applicant/Dashboard/ForgetPassword.vue'),
      },
      {
        path:"login",
        name:"Track2ApplicantLogin",
        meta: {
          layout: "Track2ApplicantEmptyLayout",
        },
        component: () => import( '../views/Track2Applicant/Dashboard/Login.vue'),
      },
      {
        path:"team-invitation",
        name:"Track2TeamInvitation",
        meta: {
          layout: "Track2ApplicantEmptyLayout",
        },
        component: () => import( '../views/Track2Applicant/Dashboard/TeamInvitation.vue'),
      },
      
      {
        path:"forgot-password",
        name:"Track2ApplicantForgetPassword",
        meta: {
          layout: "Track2ApplicantEmptyLayout",
        },
        component: () => import( '../views/Track2Applicant/Dashboard/ForgetPassword.vue'),
      },
      
    ]
  },
  {
    path: '/track2/applicant',
    meta:{
      requiredTrack2ApplicantAuth:true,
    },
    children: [
      {
        path:"create-or-join-team",
        name:"Track2CreateOrJoinTeam",
        meta: {
          layout: "Track2ApplicantEmptyLayout",
        },
        component: () => import( '../views/Track2Applicant/Dashboard/CreateOrJoinTeam.vue'),
      },
      {
        path:"create-team",
        name:"Track2CreateTeam",
        meta: {
          layout: "Track2ApplicantEmptyLayout",
        },
        component: () => import( '../views/Track2Applicant/Dashboard/CreateTeam.vue'),
      },
      {
        path:"join-team",
        name:"Track2JoinTeam",
        meta: {
          layout: "Track2ApplicantEmptyLayout",
        },
        component: () => import( '../views/Track2Applicant/Dashboard/JoinTeam.vue'),
      },
      {
        path:"accept-reject-invitation",
        name:"Track2acceptOrRejectInvitation",
        meta: {
          layout: "Track2ApplicantEmptyLayout",
        },
        component: () => import( '../views/Track2Applicant/Dashboard/acceptOrRejectInvitation.vue'),
      },
      {
        path:"mentor",
        name:"Track2BookMentor",
        meta: {
          layout: "Track2ApplicantLayout",
        },
        component: () => import( '../views/Track2Applicant/Dashboard/Mentor.vue'),
      },
      {
        path:"invite-member",
        name:"Track2InviteMember",
        meta: {
          layout: "Track2ApplicantLayout",
        },
        component: () => import( '../views/Track2Applicant/Dashboard/SendInvitation.vue'),
      },
      {
        path:"merge-team",
        name:"Track2MergeTeam",
        meta: {
          layout: "Track2ApplicantLayout",
        },
        component: () => import( '../views/Track2Applicant/Dashboard/MergeTeam.vue'),
      },
      {
        path:"feedback",
        name:"Track2Feedback",
        meta: {
          layout: "Track2ApplicantLayout",
        },
        component: () => import( '../views/Track2Applicant/Dashboard/Feedback.vue'),
      },
      {
        path:"members",
        name:"Track2Members",
        meta: {
          layout: "Track2ApplicantLayout",
        },
        component: () => import( '../views/Track2Applicant/Dashboard/Members.vue'),
      },
      {
        path:"profile",
        name:"Track2Profile",
        meta: {
          layout: "Track2ApplicantLayout",
        },
        component: () => import( '../views/Track2Applicant/Dashboard/Profile.vue'),
      },
      {
        path:"dashboard",
        name:"Track2Dashboard",
        meta: {
          layout: "Track2ApplicantLayout",
        },
        component: () => import( '../views/Track2Applicant/Dashboard/Dashboard.vue'),
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiredApplicantAuth ) {
    if( $cookies.isKey("_HackAppAT") && $cookies.isKey("_HackAppU") ){

      next();
    }
    else {
      next('/applicant/login');
    }
  }
  else if(to.meta.requiredTrack2ApplicantAuth){
    if( $cookies.isKey("_HackTrack2AppAT") && $cookies.isKey("_HackTrack2AppU") ){
      next();
    }
    else {
      next('/track2/applicant/login');
    }
  }
  else if(to.meta.requiredMentorAuth){
    if( $cookies.isKey("_HackMenAT") && $cookies.isKey("_HackMenU") ){

      next();
    }
    else {
      next('/mentor/login');
    }
  }
  else if(to.meta.requiredJudgeAuth){
    if( $cookies.isKey("_HackJudAT") && $cookies.isKey("_HackJudU") ){

      next();
    }
    else {
      next('/judge/login');
    }
  }
  else{ next();}

});

export default router
