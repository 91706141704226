import { createStore } from "vuex";

export default createStore({
  state: {
    loader: 0,
    pageLoader: 0,
    // BaseApiURL:"https://hackathon.manialab.sa/api/",
    // BaseImageURL:"https://hackathon.manialab.sa/storage/",
    // BaseApiURL: "https://testhackathon.manialab.sa/api/",
    // BaseImageURL: "https://testhackathon.manialab.sa/storage/",
    BaseApiURL: "https://k.thon.expro.gov.sa/api/",
    BaseImageURL: "https://k.thon.expro.gov.sa/storage/",
    configCms:{},
    allowedImages: ["jpg", "jpeg", "png"],
    allowedFiles: ["jpg", "jpeg", "png", "pdf"],
    allowedIdeaFiles: ["jpg", "jpeg", "png", "pdf", "pptx", "xlsx"],
    successMsg: "",
    showSuccessModal: false,
    emailRegex: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
    team: {},
    ExperinceListEN: [
      { id: "0", text: "None" },
      { id: "1", text: "1" },
      { id: "2", text: "2" },
      { id: "3+", text: "3+" },
      { id: "5+", text: "5+" },
      { id: "10+", text: "10+" },
    ],
    ExperinceListAR: [
      { id: "0", text: "لا يوجد" },
      { id: "1", text: "1" },
      { id: "2", text: "2" },
      { id: "3+", text: "3+" },
      { id: "5+", text: "5+" },
      { id: "10+", text: "10+" },
    ],
    Eligibility_Criteria: [
      {
        title_en: "Age",
        text_en: "Participants must be 18 years or older.",
        title_ar: "العمر",
        text_ar: " يجب أن يكون عمر المشاركين 18 عامًا أو أكبر.",
      },
      {
        title_en: "Residency",
        text_en: "Open to Saudi citizens only.",
        title_ar: "الإقامة",
        text_ar: "التسجيل والمشاركة مخصصة للسعودين فقط",
      },
      {
        title_en: "Team Composition",
        text_en:
          "Teams should consist a minimum of 5 members and a maximum of 6, with diverse skill sets as specified. ",
        title_ar: "تكوين الفريق",
        text_ar:
          " يجب أن تتكون الفرق من 5 أعضاء على الأقل و6 كحد أقصى، مع مجموعات مهارات متنوعة كما هو محدد ",
      },
      {
        title_en: "Professional Background",
        text_en:
          "Participants from various sectors, including finance, technology, sustainability, procurement, and strattegy are encouraged to join.",
        title_ar: "الخلفية المهنية",
        text_ar:
          "يُشجّع المشاركون من مختلق القطاعات، بما في ذلك المالية، التكنولوجيا، والمشتريات المنشآت الناشئة، والمخترعون على الإنضمام",
      },
      {
        title_en: "Experience Level",
        text_en:
          "Open to both professionals and students with relevant experience.",
        title_ar: "مستوى الخبرة",
        text_ar:
          "متاح لكل من المهنين والطلاب الذين يمتلكون الخبرة.",
      },
      {
        title_en: "Commitment",
        text_en:
          "Participants must be available for the full duration of the challenge, including workshops, mentorship sessions, and final presentations.",
        title_ar: "الالتزام",
        text_ar:
          "يجب أن يكون المشاركين متاحين  طوال مدة تحدي الإبتكار بما في ذلك ورش العمل وجلسات الإرشاد والعروض التقديمية النهائية.",
      },
      {
        title_en: "Ethics and Compliance",
        text_en:
          "Participants must adhere to the rules and guidelines of the hackathon, including intellectual property rights, and must conduct themselves with integrity throughout the event.",
        title_ar: "الأخلاقيات والامتثال",
        text_ar:
          "يجب على المشاركين الالتزام بالقواعد والإرشادات الخاصة بالتحدي، بما في لك حقوق الملكية الفكرية، وأن يتحلوا بالنزاهة طوال فترة الفعالية",
      },
    ],
    terms: [
      {
        title_en: "General Rules",
        title_ar: "القواعد العامة",
        text: [
          {
            sub_en: "Participation Eligibility",
            sub_ar: "أهلية المشاركة",
            cont_en:
              "The Innovation Challenge is open to Saudi citizens aged 18 years and older. Participants must form teams of 5-6 members. ",
            cont_ar:
              "الهاكاثون مفتوح للمواطنين السعوديين الذين تبلغ أعمارهم 18 عامًا أو أكثر. يجب على المشاركين تشكيل فرق مكونة من 5-6 أعضاء.",
          },
          {
            sub_en: "Team Formation",
            sub_ar: "تشكيل الفريق",
            cont_en:
              "Teams must have Business Analyst, Backend Developer, Frontend Developer, Data Scientist/AI Specialist, Procurement Specialist, and an optional Wild Card member (such as an additional developer, AI expert, or sustainability expert)",
            cont_ar:
              "يجب أن تضم الفرق محلل أعمال ومطورًا خلفيًا ومطورًا أماميًا وعالم بيانات/متخصصًا في الذكاء الاصطناعي وأخصائي مشتريات وعضوًا اختياريًا (مثل مطور إضافي أو خبير في الذكاء الاصطناعي أو خبير في الاستدامة .",
          },
          {
            sub_en: "Commitment",
            sub_ar: "الالتزام",
            cont_en:
              "All participants must be available for the full duration of Kafa'athon including the preparation phase, workshops, mentorship sessions, and final presentations.",
            cont_ar:
              "يجب أن يكون المشاركين متاحين  طوال مدة تحدي الإبتكار بما في ذلك ورش العمل وجلسات الإرشاد والعروض التقديمية النهائية.",
          },
          {
            sub_en: "Code of Conduct",
            sub_ar: "مدونة قواعد السلوك",
            cont_en:
              "Participants are expected to conduct themselves professionally and respectfully toward fellow participants, mentors, judges, and organizers. Any form of harassment, discrimination, or unethical behavior will result in disqualification.",
            cont_ar:
              "من المتوقع أن يتصرف المشاركون باحترافية واحترام تجاه زملائهم المشاركين، والموجهين، والحكام، والمنظمين. أي شكل من أشكال التحرش أو التمييز أو السلوك غير الأخلاقي سيؤدي إلى الاستبعاد",
          },
          {
            sub_en: "Ethics and Compliance",
            sub_ar: "الأخلاق والامتثال",
            cont_en:
              "Participants must adhere to intellectual property rights, data privacy regulations, and ethical AI practices. Teams must ensure their solutions are original and do not infringe on existing patents or proprietary technologies.",
            cont_ar:
              "يجب على المشاركين الالتزام بحقوق الملكية الفكرية ولوائح خصوصية البيانات وممارسات الذكاء الاصطناعي الأخلاقية. يجب على الفرق التأكد من أن حلولهم أصلية ولا تنتهك براءات الاختراع الحالية أو التقنيات الملكية",
          },
        ],
      },
      {
        title_en: "Intellectual Property",
        title_ar: "الملكية الفكرية",
        text: [
          {
            sub_en: "Ownership",
            sub_ar: "الملكية",
            cont_en:
              "Intellectual property rights (IPR) for the projects developed during Kafa'athon will be retained by the participants. However, organizers reserve the right to use and showcase the projects for promotional purposes.",
            cont_ar:
              "سوف يحتفظ المشاركون بحقوق الملكية الفكرية للمشاريع التي تم تطويرها خلال كفاءةثون ومع ذلك، يحتفظ المنظمون بالحق في استخدام المشاريع وعرضها لأغراض ترويجية مع الإشادة بملاك الفكرة.",
          },
          {
            sub_en: "Open-Source Componentss",
            sub_ar: "مكونات المصدر المفتوح",
            cont_en:
              " your project uses open-source software, ensure it complies with the applicable licenses, and credit the creators accordingly.",
            cont_ar:
              "إذا كان مشروعك يستخدم برنامجًا مفتوح المصدر، فيجب التأكد من امتثاله للتراخيص المعمول بها واعطاء الفضل للمبدعين طبقاً لذلك",
          },
        ],
      },
    ],
    Ideaterms: [
      {
        title_en: "Comprehensive Cost Database",
        title_ar: "قاعدة بيانات التكلفة الشاملة",
        text: [
          {
            cont_en:
              "The ability to maintain a robust database of historical and current costs, including materials, labor, and other resources.",
            cont_ar:
              "القدرة على الاحتفاظ بقاعدة بيانات قوية للتكاليف السابقة والحالية، بما في ذلك المواد والقوى العاملة والموارد الأخرى.",
          },
          {
            cont_en:
              "Continuously update and expand the database to reflect the most accurate and relevant data with the ability to import/export data from various sources and formats.",
            cont_ar:
              "تحديث قاعدة البيانات وتوسيعها باستمرار حتى تعكس البيانات الأكثر دقة وارتباطًا مع القدرة على استيراد/تصدير البيانات من مصادر وتنسيقات مختلفة.",
          },
          {
            cont_en:
              "For the purpose of Kafa'athon, model data sheets will be provided to the participants.",
            cont_ar:
              "لغرض كفاءة ثون، سيتم توفير أوراق بيانات نموذجية للمشاركين",
          },
        ],
        sub: [],
      },
      {
        title_en: "Generative AI-Driven Forecasting",
        title_ar: "التنبؤ القائم على الذكاء الاصطناعي التوليدي",
        text: [
          {
            cont_en:
              "Utilize generative AI to predict future cost trends, providing users with data-driven insights for more accurate and informed budgetary decisions.",
            cont_ar:
              "استخدم الذكاء الاصطناعي التوليدي للتنبؤ باتجاهات التكلفة المستقبلية، وتزويد المستخدمين برؤى قائمة على البيانات لاتخاذ قرارات تحديد الميزانيات بدقة أكثر",
          },
          {
            cont_en:
              "Integrate machine learning algorithms to refine predictions based on real-time data and evolving market conditions.",
            cont_ar:
              "دمج خوارزميات التعلم الآلي لتحسين التنبؤات بناءً على البيانات في الوقت الفعلي وظروف السوق المتطورة",
          },
          {
            cont_en:
              "Scenario analysis features to simulate different budgetary outcomes based on various assumptions.",
            cont_ar:
              "ميزات تحليل السيناريو لمحاكاة نتائج الميزانية المختلفة بناءً على افتراضات مختلفة",
          },
          {
            cont_en:
              "AI forecasting should support at least 3 iterations to ensure data accuracy.",
            cont_ar:
              "يجب أن يدعم التنبؤ بالذكاء الاصطناعي في 3 تكرارات على الأقل لضمان دقة البيانات",
          },
          {
            cont_en: "Forecast should be for 1 year minimum. ",
            cont_ar: "يجب أن تكون التوقعات لمدة عام واحد على الأقل",
          },
        ],
        sub: [],
      },
      {
        title_en: "Budget Allocation Tools",
        title_ar: "أدوات تخصيص الميزانية",
        text: [
          {
            cont_en:
              "Enable precise budget allocation tailored to the specific needs and requirements of each project.",
            cont_ar:
              "تمكين تخصيص الميزانية بدقة وفقًا للاحتياجات والمتطلبات المحددة لكل مشروع",
          },
          {
            cont_en:
              "Incorporate a budget variance tool to track discrepancies between planned and actual spending, facilitating continuous improvement in budgeting practices.",
            cont_ar:
              "دمج أداة تباين الميزانية لتتبع التناقضات بين الإنفاق المخطط والفعلي، مما يسهل التحسين المستمر في ممارسات الميزانية",
          },
          {
            cont_en:
              "Provide government entities with access to variance analysis for better preparation of future RFPs for similar projects. ",
            cont_ar:
              "تزويد الجهات الحكومية بإمكانية الوصول إلى تحليل التباين لإعداد أفضل لطلبات تقديم العروض المستقبلية للمشاريع المماثلة.",
          },
        ],
        sub: [],
      },
      {
        title_en: "Sustainability Metrics",
        title_ar: "",
        text: [
          {
            cont_en:
              "Mark raw materials and other resources with sustainability scores, helping users prioritize environmentally responsible choices.",
            cont_ar:
              "تحديد المواد الخام والموارد الأخرى بدرجات الاستدامة، مما يساعد المستخدمين على تحديد أولويات الخيارات المسؤولة بيئيًا",
          },
          {
            cont_en:
              "Include a feature that identifies opportunities for re-using resources from past projects, promoting circular economy practices and reducing waste.",
            cont_ar:
              "تضمين ميزة تحدد الفرص لإعادة استخدام الموارد من المشاريع السابقة، وتعزيز ممارسات الاقتصاد الدائري والحد من النفايات",
          },
        ],
        sub: [
          {
            sub_main_title_en:
              "Suggested sustainability criteria for resource grading. (The participants are expected to define their own grading system)",
            sub_main_title_ar:
              "معايير الاستدامة المقترحة لتصنيف الموارد. (من المتوقع أن يحدد المشاركون نظام التصنيف الخاص بهم)",
            sub_list: [
              {
                sub_title_en: "Carbon Footprint",
                sub_title_ar: "البصمة الكربونية",
                sub_text: [
                  {
                    sub_cont_en:
                      "<span class='b'>Definition: </span> <span class='ml2'>The total greenhouse gas emissions produced throughout the lifecycle of the material or resource, from extraction to disposal.</span>",
                    sub_cont_ar:
                      "<span class='b'>التعريف:</span> <span class='ml2'>إجمالي انبعاثات الغازات المسببة للانحباس الحراري العالمي المنتجة طوال دورة حياة المادة أو المورد، من الاستخراج إلى التخلص منها</span>",
                  },
                  {
                    sub_cont_en:
                      "<span class='b'>Scoring Criteria:</span> <span class='ml2'>Lower carbon emissions score higher on the sustainability scale.</span>",
                    sub_cont_ar:
                      "<span class='b'>معايير التسجيل:</span> <span class='ml2'>انبعاثات الكربون المنخفضة تسجل درجة أعلى على مقياس الاستدامة.</span>",
                  },
                ],
              },
              {
                sub_title_en: "Renewability",
                sub_title_ar: "القدرة على التجديد",
                sub_text: [
                  {
                    sub_cont_en:
                      "<span class='b'>Definition: </span> <span class='ml2'>The ability of a resource to be replenished naturally over time.</span>",
                    sub_cont_ar:
                      "<span class='b'>التعريف:</span> <span class='ml2'>قدرة الموارد على التجديد بشكل طبيعي بمرور الوقت</span>",
                  },
                  {
                    sub_cont_en:
                      "<span class='b'>Scoring Criteria:</span> <span class='ml2'>Renewable resources (e.g., bamboo, recycled metals) score higher than non-renewable ones (e.g., fossil fuels, rare minerals).</span>",
                    sub_cont_ar:
                      "<span class='b'>معايير التسجيل:</span> <span class='ml2'>تحصل الموارد المتجددة (مثل الخيزران والمعادن المعاد تدويرها) على درجات أعلى من الموارد غير المتجددة (مثل الوقود الأحفوري والمعادن النادرة)</span>",
                  },
                ],
              },
              {
                sub_title_en: "Recyclability",
                sub_title_ar: "قابلية إعادة التدوير",
                sub_text: [
                  {
                    sub_cont_en:
                      "<span class='b'>Definition: </span> <span class='ml2'>The ease with which a material can be recycled or repurposed at the end of its useful life.</span>",
                    sub_cont_ar:
                      "<span class='b'>التعريف:</span> <span class='ml2'>سهولة إعادة تدوير مادة ما أو إعادة استخدامها في نهاية عمرها الإنتاجي</span>",
                  },
                  {
                    sub_cont_en:
                      "<span class='b'>Scoring Criteria:</span> <span class='ml2'>Materials that can be fully recycled or have high post-consumer recycling rates score higher.</span>",
                    sub_cont_ar:
                      "<span class='b'>معايير التسجيل:</span> <span class='ml2'>المواد التي يمكن إعادة تدويرها بالكامل أو التي تتمتع بمعدلات إعادة تدوير عالية بعد الاستهلاك تحصل على درجات أعلى.</span>",
                  },
                ],
              },

              {
                sub_title_en: "Resource Efficiency",
                sub_title_ar: "كفاءة الموارد",
                sub_text: [
                  {
                    sub_cont_en:
                      "<span class='b'>Definition: </span> <span class='ml2'>The amount of raw material required to produce a unit of output and the efficiency with which it is used.</span>",
                    sub_cont_ar:
                      "<span class='b'التعريف:></span> <span class='ml2'></span>",
                  },
                  {
                    sub_cont_en:
                      "<span class='b'>Scoring Criteria:</span> <span class='ml2'>Resources that minimize waste and maximize output score higher.</span>",
                    sub_cont_ar:
                      "<span class='b'>معايير التسجيل:</span> <span class='ml2'></span>",
                  },
                ],
              },
              {
                sub_title_en: "End-of-Life Disposal",
                sub_title_ar: "",
                sub_text: [
                  {
                    sub_cont_en:
                      "<span class='b'>Definition: </span> <span class='ml2'>The impact of disposing of the material at the end of its life, including biodegradability and landfill space required.</span>",
                    sub_cont_ar:
                      "<span class='b'>التعريف:</span> <span class='ml2'></span>",
                  },
                  {
                    sub_cont_en:
                      "<span class='b'>Scoring Criteria:</span> <span class='ml2'>Materials that are biodegradable, compostable, or have minimal disposal impact score higher.</span>",
                    sub_cont_ar:
                      "<span class='b'>معايير التسجيل:</span> <span class='ml2'></span>",
                  },
                ],
              },
              {
                sub_title_en: "Energy Efficiency in Production",
                sub_title_ar: "",
                sub_text: [
                  {
                    sub_cont_en:
                      "<span class='b'>Definition: </span> <span class='ml2'>The amount of energy required to produce the material or resource.</span>",
                    sub_cont_ar:
                      "<span class='b'>التعريف:</span> <span class='ml2'></span>",
                  },
                  {
                    sub_cont_en:
                      "<span class='b'>Scoring Criteria:</span> <span class='ml2'>Materials produced with renewable energy or through energy-efficient processes score higher.</span>",
                    sub_cont_ar:
                      "<span class='b'>معايير التسجيل:</span> <span class='ml2'></span>",
                  },
                ],
              },
              {
                sub_title_en: "Local Sourcing",
                sub_title_ar: "",
                sub_text: [
                  {
                    sub_cont_en:
                      "<span class='b'>Definition: </span> <span class='ml2'>The proximity of the resource to the project site, reducing transportation emissions and supporting local economies.</span>",
                    sub_cont_ar:
                      "<span class='b'>التعريف:</span> <span class='ml2'></span>",
                  },
                  {
                    sub_cont_en:
                      "<span class='b'>Scoring Criteria:</span> <span class='ml2'>Locally sourced materials score higher due to reduced transportation impact and support for local communities.</span>",
                    sub_cont_ar:
                      "<span class='b'>معايير التسجيل:</span> <span class='ml2'></span>",
                  },
                ],
              },

              {
                sub_text: [
                  {
                    sub_cont_en: "",
                    sub_cont_ar: "",
                  },
                  {
                    sub_cont_en: "",
                    sub_cont_ar: "",
                  },
                ],
              },
              {
                sub_text: [
                  {
                    sub_cont_en: "",
                    sub_cont_ar: "",
                  },
                  {
                    sub_cont_en: "",
                    sub_cont_ar: "",
                  },
                  {
                    sub_cont_en: "",
                    sub_cont_ar: "",
                  },
                ],
              },
              {
                sub_text: [
                  {
                    sub_cont_en:
                      "Technical details about the database building, data collecting, data analysis technologies.",
                    sub_cont_ar: "",
                  },
                  {
                    sub_cont_en:
                      "Details about the backend architecture, frontend framework and API.",
                    sub_cont_ar: "",
                  },
                ],
              },
              {
                sub_text: [
                  {
                    sub_cont_en:
                      "Provide real-time reporting and analytics tools, allowing users to monitor project budgets, costs, and resource allocation dynamically.",
                    sub_cont_ar: "",
                  },
                  {
                    sub_cont_en:
                      "Generate detailed reports that can be customized for different stakeholders, offering transparency and accountability.",
                    sub_cont_ar: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title_en: "Resource Reusability",
        title_ar: "",
        text: [
          {
            cont_en:
              "Facilitate the re-use of past resources in future projects by the same entity, based on recommendations generated by the software.",
            cont_ar: "",
          },
          {
            cont_en:
              "Store and track the utilization of resources to optimize efficiency and minimize costs.",
            cont_ar: "",
          },
        ],
        sub: [],
      },
      {
        title_en: "Scalable and User-Friendly Interface",
        title_ar: "",
        text: [
          {
            cont_en:
              "Design a user-friendly interface that ensures ease of use for all users, regardless of technical expertise.",
            cont_ar: "",
          },
          {
            cont_en:
              "Ensure the software is scalable to accommodate the needs of both small and large projects, with the flexibility to grow alongside the entity.",
            cont_ar: "",
          },
        ],
        sub: [],
      },
      {
        title_en: "Integration with Existing Systems",
        title_ar: "",
        text: [
          {
            cont_en:
              "Develop the software to seamlessly integrate with existing government systems, enabling smooth data transfer and interoperability.",
            cont_ar: "",
          },
          {
            cont_en:
              "Provide APIs and other integration tools to support connectivity with various platforms and databases.",
            cont_ar: "",
          },
        ],
        sub: [],
      },
      {
        title_en: "Technical Support, Add-ons and Maintenance",
        title_ar: "",
        text: [
          {
            cont_en:
              "Include a comprehensive technical support manual detailing maintenance requirements, troubleshooting steps, and best practices.",
            cont_ar: "",
          },
          {
            cont_en:
              "Offer ongoing support and updates to ensure the software remains reliable and up-to-date with the latest technologies and user needs.",
            cont_ar: "",
          },
          {
            cont_en:
              "Suggest in details features that could be added to the software for continuous improvement.",
            cont_ar: "",
          },
        ],
        sub: [],
      },
      {
        title_en: "Technical Details",
        title_ar: "",
        text: [
          {
            cont_en: "",
            cont_ar: "",
          },
          {
            cont_en: "",
            cont_ar: "",
          },
          {
            cont_en: "",
            cont_ar: "",
          },
        ],
        sub: [],
      },
      {
        title_en: "Real-Time Reporting and Analytics",
        title_ar: "",
        text: [
          {
            cont_en: "",
            cont_ar: "",
          },
          {
            cont_en: "",
            cont_ar: "",
          },
          {
            cont_en: "",
            cont_ar: "",
          },
        ],
        sub: [],
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
