<template>
  <div class="cNavy fs0" style="min-height: 100vh;">
    <div class="pf oh  applicantSideBar fs0 fs0 cov" style="width: 300px;" :style="{ backgroundImage: 'url(' + require('@/assets/imgs/patternBg/appSideBg.png') + ')' }"> 
      
      <div class="z100 ph10" style="max-width: 100%;" >
        <img src="@/assets/imgs/logo_new.png" alt=""  class="w100" style="max-width:200px;" >
      </div>
      <div class="oya h100 c mt50 sideBarInner">
        <div class="pv8">
          <router-link class="g_nm" :to="{ name: 'Track2Dashboard' }">
            <div class="l applicatMenuTab g_nm" :class="{'active' :  $route.name == 'Track2Dashboard' }"><span class="fs24 mdi mdi-apps vm"></span><span class="ml4 lh24 vm cap">{{ $t('dashboard') }}</span></div>
          </router-link>
        </div>
        <div class="pv8">
          <router-link class="g_nm" :to="{ name: 'Track2Profile' }">
            <div class="l applicatMenuTab g_nm" :class="{'active' :  $route.name == 'Track2Profile' }"><span class="fs24 mdi mdi-account vm"></span><span class="ml4 lh24 vm cap">{{ $t('profile') }}</span></div>
          </router-link>
        </div>
        <div class="pv8">
          <router-link class="g_nm" :to="{ name: 'Track2Members' }">
            <div class="l applicatMenuTab g_nm" :class="{'active' :  $route.name == 'Track2Members' }"><span class="fs24 mdi mdi-account-multiple vm"></span><span class="ml4 lh24 vm cap">{{ $t('members') }}</span></div>
          </router-link>
        </div>
        <div class="pv8" v-if="this.$store.state.configCms.second_book_session==1">
        <!-- <div class="pv8" v-if="this.$store.state.team.qualified!='not-qualified'"> -->
          <router-link class="g_nm" :to="{ name: 'Track2BookMentor' }">
            <div class="l applicatMenuTab g_nm" :class="{'active' :  $route.name == 'Track2BookMentor' }"><span class="fs24 mdi mdi-message mt4"></span><span class="ml4 vm cap">{{ $t('mentors') }}</span></div>
          </router-link>
        </div>
        
        <!-- <div class="pv8">
          <router-link class="g_nm" :to="{ name: 'Track2Feedback' }">
            <div class="l applicatMenuTab g_nm" :class="{'active' :  $route.name == 'Track2Feedback' }"><span class="fs24 mdi mdi-chart-bar vm"></span><span class="ml4 lh24 vm cap">{{ $t('feedback') }}</span><span class="danger ml4">(0)</span></div>
          </router-link>
        </div> -->
        <div class="pv8" v-if="this.user.role=='admin' && $store.state.team?.applicants?.length<5 && $store.state.configCms.second_initial_upload==1 && $store.state.team.qualified=='not-defined'">
          <router-link class="g_nm" :to="{ name: 'Track2InviteMember' }">
            <div class="l applicatMenuTab g_nm"  :class="{'active' :  $route.name == 'Track2InviteMember' }"><span class="fs24 mdi mdi-chart-bar vm"></span><span class="ml4 lh24 vm cap">{{ $t('Invite member') }}</span></div>
          </router-link>
        </div>
        <div class="pv8" v-if="this.user.role=='admin' && $store.state.team?.applicants?.length<3 && $store.state.configCms.second_initial_upload== 1 && $store.state.team.qualified=='not-defined'">
          <router-link class="g_nm" :to="{ name: 'Track2MergeTeam' }">
            <div class="l applicatMenuTab g_nm"  :class="{'active' :  $route.name == 'Track2MergeTeam' }"><span class="fs24 mdi mdi-account-multiple-plus-outline vm"></span><span class="ml4 lh24 vm">{{ $t('Merge with a team') }}</span></div>
          </router-link>
        </div>
        <div class="mt30 c">
          <div class="logoutApplicatn g_nm pointer l" @click="logoutApplicant()"><span class="fs24 mdi mdi-logout mt" ></span><span class=" ml4 cap fs14 vm ">{{ $t('logout') }}</span></div>
        </div>  
      </div>
      
      <div class="ov mt10 ml15 ra h m_sb"><span class=" pointer fs28 ba danger mdi mdi-close-thick z200" @click="closeMenu()"></span></div>
    </div>
    <div class="aplicatanForm pt40 l ph30">
      <router-view></router-view>
    </div>  
    <div v-if="$store.state.loader==1" class="pf ov  Loader300" style="z-index: 999999;">
      <div class="bgw h100"><div class="cc">
        <img src="@/assets/imgs/loader.gif"  style="max-width: 250px; opacity: 0.7;"/>
        <!-- <img src="@/assets/imgs/logo_new.png" alt="" class="loaderImage"> -->
      </div></div>
    </div>
    <div class="ov20 z900 la ba pointer " @click="changeLang()"><span class="mdi mdi-earth fs30 cNavy vm mt2"></span></div>
    <div class="ov20 z200 ra ba h m_sb"><span class="pointer mdi mdi-menu fs30 cNavy vm" @click="openMenu()"></span> </div>
  </div>
</template>

<script>
export default {
  name: 'ApplicantLayout',
  data(){
    return{
      disabledSubmit:false,
      errors:0,
      at:null,
      user:{},
      configCms:{},
    }
  },
  created(){
    if(this.$cookies.isKey("_HackLang")){
      if(this.$cookies.get("_HackLang")=='en'){
        this.$i18n.locale='ar';
      }
      else{
        this.$i18n.locale='en';
      }
      this.changeLang()
    }
      if(this.$cookies.isKey("_HackTrack2AppU") && this.$cookies.isKey("_HackTrack2AppAT")){
        this.at=this.$cookies.get("_HackTrack2AppAT")
        this.user=this.$cookies.get("_HackTrack2AppU")
      }
      else{
        this.$router.push({ name: 'Track2ApplicantLogin'});
      }
  },
  
  methods: {
    logoutApplicant(){
        this.$cookies.remove("_HackTrack2AppAT")
        this.$cookies.remove("_HackTrack2AppU")
        this.$router.push({ name: 'Track2ApplicantLogin'});  


      
    },
    changeLang(){
      if(this.$i18n.locale=="en"){
        $('html').attr('lang', 'ar');  
        this.$i18n.locale='ar';
        $('body').addClass('rtl');
        const expirationTime =  100 * 60 * 60;
        this.$cookies.set("_HackLang", 'ar', expirationTime);
      }
      else{
        $('html').attr('lang', 'en');  
        this.$i18n.locale='en';
        $('body').removeClass('rtl');
        const expirationTime =  100 * 60 * 60;
        this.$cookies.set("_HackLang", 'en', expirationTime);
      }
    },
    openMenu(){
      $('.applicantSideBar').addClass('active');
    },
    closeMenu(){
      $('.applicantSideBar').removeClass('active');
    }
  }
}
</script>
